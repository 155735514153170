<template>
  <div class="stu_list" v-loading="loading">
    <div class="close">
      <i class="el-icon-circle-close" @click="closeAction"></i>
    </div>
    <div
      :class="[
        'stu',
        {
          you: item.jibie >= 90,
          liang: item.jibie >= 60 && item.jibie < 90,
          cha: item.jibie < 60,
        },
      ]"
      v-for="item in stus"
      :key="item.id"
      @click="skipAction(row, item)"
    >
      <h3>{{ item.stu_name }}</h3>
      <p>完成度:{{ item.jibie }}%</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "StuList",
  data() {
    return {
      stus: [],
      row: {},
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    this.row = JSON.parse(localStorage.getItem("row"));
    let className = localStorage.getItem("className");
   
    if (this.$route.query.row.className) {
      localStorage.setItem("className", this.$route.query.row.className);
      className = this.$route.query.row.className;
      this.row = this.$route.query.row;
      localStorage.setItem("row", JSON.stringify(this.row));
    }
    this.$datas.meta = {id: this.row.id.split('-')[1], kind:1};
    let rowData = await this.$datas.getXMC;
    console.log(rowData.data);
    this.row = {...rowData.data, id:this.row.id};
    this.$datas.meta = { className: className };
    let res = await this.$datas.getStus;
    let res1 = {data:this.row};
    //获取班级学生表中的数据
    // // 如果为空，此时需要添加学生
    if (res1.data.bjs.length == 0) {
      for (let i in res.data.msg.data) {
        this.$datas.meta = {
          xm_id: this.row.id.split("-")[1],
          stu_name: res.data.msg.data[i].name,
          tel:res.data.msg.data[i].phone,
        };
        let res2 = await this.$datas.addStudents;
        this.stus.push(res2.data);
      }
    } else {
      this.stus = res1.data.bjs;
      for(let i in this.stus){
        if(this.stus[i].kind == 0){
            this.stus.splice(i, 1);
        }
       }
    }
    this.loading = false;
  },
  methods: {
    closeAction() {
      this.$router.go(-1);
    },
    async skipAction(row, item) {
      this.loading = true;
      if (item.bjc.length <= 0) {
        //更新功能
        let xms = row.xm.xms;
        for (let i in xms) {
          let item1 = xms[i];
          this.$datas.meta = {
            xm_id: row.id.split("-")[1],
            stu_id: item.id,
            gongneng_id: item1.gongneng_id,
            gongneng_name: item1.gongneng_name,
            quanzhong:item1.quanzhong
          };
          await this.$datas.addBJCheck;
        }
      }
      this.loading = false;
      //页面跳转
      this.$router.push({ name: "CheckXM", query: { id: item.id, xm_id:this.row.id.split("-")[1]} });
    },
  },
};
</script>
<style lang="less" scoped>
.stu_list {
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
  .close {
    padding: 10px 20px;
    text-align: right;
    i {
      font-size: 25px;
      cursor: pointer;
      color: #555;
      &:hover {
        color: #aaff;
      }
    }
  }
  .stu {
    width: 120px;
    padding: 5px 10px;
    border: 1px solid #999;
    border-radius: 5px;
    float: left;
    margin: 10px;
    cursor: pointer;
    p {
      font-size: 12px;
      margin-top: 5px;
    }
  }
  .liang {
    background-color: #409eff;
    color: white;
    border: 1px solid #409eff;
  }
  .cha {
    background-color: #f56c6c;
    color: white;
    border: 1px solid #f56c6c;
  }
}
</style>
